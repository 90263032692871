import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './css/main.css'
import 'font-awesome/css/font-awesome.min.css'
import { postRequest } from "./utils/api";
import { postKeyValueRequest } from "./utils/api";
import { putRequest } from "./utils/api";
import { deleteRequest } from "./utils/api";
import { initMenu } from "./utils/menus";
import { getRequest } from "./utils/api";
import { prxPh } from "./utils/constants";

Vue.prototype.postRequest = postRequest;
Vue.prototype.postKeyValueRequest = postKeyValueRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.deleteRequest = deleteRequest;
Vue.prototype.getRequest = getRequest;
//跨域配置一致
Vue.prototype.ph = prxPh;
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    if (to.path == '/login') {
        next();
    } else {

        if (window.sessionStorage.getItem("user")) {
            initMenu(router, store);
            next();
        } else {
            next('/?redirect=' + to.path);
        }
    }
})
console.log(`router`, router)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')



