import { render, staticRenderFns } from "./BrandInfoDetail.vue?vue&type=template&id=131e5993"
import script from "./BrandInfoDetail.vue?vue&type=script&lang=js"
export * from "./BrandInfoDetail.vue?vue&type=script&lang=js"
import style0 from "./BrandInfoDetail.vue?vue&type=style&index=0&id=131e5993&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports