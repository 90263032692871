<template>
    <div>
        <div>
            <div style="display: flex;justify-content: space-between">
                
                <div>
                    <!--
                    <el-input placeholder="请输入对象户名进行搜索，可以直接回车搜索..." prefix-icon="el-icon-search" clearable @clear="initObjectDto" style="width: 350px;margin-right: 10px" v-model="keyword" @keydown.enter.native="initObjectDto" :disabled="showAdvanceSearchView"></el-input>
                    
                    <el-button icon="el-icon-search" type="primary" @click="initObjectDto" :disabled="showAdvanceSearchView">
                        搜索
                    </el-button>
                    -->
                    <el-button type="primary" @click="showAdvanceSearchView = !showAdvanceSearchView">
                        <i :class="showAdvanceSearchView?'fa fa-angle-double-up':'fa fa-angle-double-down'" aria-hidden="true"></i>
                        搜索条件
                    </el-button>
                </div>
                
                <div>
                    <!--
                    <el-upload :show-file-list="false" :before-upload="beforeUpload" :on-success="onSuccess" :on-error="onError" :disabled="importDataDisabled" style="display: inline-flex;margin-right: 8px" action="/wjcom/projectInfo/basic/import">
                        <el-button :disabled="importDataDisabled" type="success" :icon="importDataBtnIcon">
                            {{importDataBtnText}}
                        </el-button>
                    </el-upload> -->
                    <el-button type="success" @click="exportData" icon="el-icon-download">
                        导出数据
                    </el-button>
                    <!--
                    <el-button type="primary" icon="el-icon-plus" @click="showAddObjView">
                        添加数据
                    </el-button>
                    -->
                </div>
            </div>
            <transition name="slide-fade">
                <div v-show="showAdvanceSearchView" style="border: 1px solid #409eff;border-radius: 5px;box-sizing: border-box;padding: 5px;margin: 10px 0px;">
                    <el-row>
                        <el-col :span="5">
                            状态:
                            <el-select v-model="searchValue.status" placeholder="状态" size="mini" style="width: 130px;">
                                <el-option v-for="item in status" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-col>

                        <el-col :span="5">
                            成本中心:
                            <el-input size="mini" style="width: 150px" prefix-icon="el-icon-edit" v-model="searchValue.projectManagerName" placeholder="请输入成本中心"></el-input>
                        </el-col>
                        <el-col :span="5">
                            客户名称:
                            <el-input size="mini" style="width: 150px" prefix-icon="el-icon-edit" v-model="searchValue.customerName" placeholder="请输入客户名称"></el-input>
                        </el-col>
                        <el-col :span="5">
                            项目名称:
                            <el-input size="mini" style="width: 150px" prefix-icon="el-icon-edit" v-model="searchValue.projectName" placeholder="请输入项目名称"></el-input>
                        </el-col>



                        <el-col :span="5" :offset="4">
                            <el-button size="mini" icon="el-icon-search" type="primary" @click="initObjectDto()">搜索</el-button>
                        </el-col>
                    </el-row>
                </div>
            </transition>
        </div>
        <div style="margin-top: 10px">
            <el-table :data="objectDtos" stripe border v-loading="loading" element-loading-text="正在加载..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" style="width: 100%">

                <el-table-column prop="businessDate" label="日期" align="center" width="100">
                </el-table-column>
                <el-table-column prop="customerName" label="客户名称" align="center" width="100">
                </el-table-column>
                <el-table-column prop="projectManagerName" label="项目经理姓名" align="center" width="110">
                </el-table-column>
                <el-table-column :formatter="percentFormat" prop="projectManagerDeduct" label="项目经理扣点" align="center" width="110">
                </el-table-column>
                <el-table-column prop="projectAssistantName" label="助理姓名" align="center" width="100">
                </el-table-column>
                <el-table-column :formatter="percentFormat" prop="projectAssistantDeduct" label="助理扣点" align="center" width="80">
                </el-table-column>
                <el-table-column prop="salesUserName" label="销售姓名" align="center" width="80">
                </el-table-column>
                <el-table-column :formatter="percentFormat" prop="salesUserDeduct" label="销售扣点" align="center" width="80">
                </el-table-column>
                <el-table-column prop="channelName" label="渠道姓名" align="center" width="80">
                </el-table-column>
                <el-table-column :formatter="percentFormat" prop="channelDeduct" label="渠道扣点" align="center" width="80">
                </el-table-column>
                <el-table-column prop="projectName" label="项目名称" align="center" width="120">
                </el-table-column>
                <el-table-column prop="totalIncome" label="合同金额" align="center" width="120">
                </el-table-column>
                <el-table-column prop="actIncome" label="已收款" align="center" width="120">
                </el-table-column>
                <el-table-column prop="notReceiveAmount" label="未收款" align="center" width="120">
                </el-table-column>
                <el-table-column :formatter="percentFormat" prop="receiveRate" label="收款比例" align="center" width="80">
                </el-table-column>
                <el-table-column prop="totalPayAmount" label="项目成本" align="center" width="120">
                </el-table-column>
                <el-table-column :formatter="percentFormat" prop="profitRate" label="利润率" align="center" width="80">
                </el-table-column>
                <el-table-column prop="totalInvoiceAmount" label="已开票" align="center" width="120">
                </el-table-column>
                <el-table-column :formatter="percentFormat" prop="invoiceRate" label="开票率" align="center" width="80">
                </el-table-column>
                <el-table-column v-if="netEditAuth" fixed="right" width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button @click="showEditObj(scope.row)" style="padding: 3px" size="mini">编辑</el-button>
                        <el-button @click="deleteObj(scope.row)" style="padding: 3px" size="mini" type="danger">删除</el-button>
                        <el-button @click="applyObj(scope.row)" style="padding: 3px" size="mini">重提</el-button>   
                    </template>
                </el-table-column>
                
            </el-table>
            <div style="display: flex;justify-content: flex-end">
                <el-pagination background @current-change="currentChange" @size-change="sizeChange" layout="sizes, prev, pager, next, jumper, ->, total, slot" :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="80%">
            <div>
                <el-form :model="objectDto" :rules="rules" ref="objectDtoForm">
                    <el-row>
                        <el-col :span="5">
                            <el-form-item label="项目编号:" prop="projectId">
                                <el-input disabled size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.projectId" placeholder="请输入项目ID"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="5">
                            <el-form-item label="日期:" prop="businessDate">
                                <el-date-picker v-model="objectDto.businessDate" size="mini" type="date" value-format="yyyy-MM-dd" style="width: 180px;" placeholder="请输入日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>

                        <el-col :span="5">
                            <el-form-item label="客户名称:" prop="customerName">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.customerName" placeholder="请输入客户名称"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="5">
                            <el-form-item label="项目经理:" prop="projectManagerId">
                                <el-select v-model="objectDto.projectManagerId" placeholder="项目经理:" size="mini" style="width: 150px;" v-on:change="selectPmName" >
                                    <el-option v-for="item in pmList" :key="item.id" :label="item.name" :value="item.id" >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>

                        <el-col :span="5">
                            <el-form-item label="项目经理扣点:" prop="projectManagerDeduct">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.projectManagerDeduct" placeholder="请输入项目经理扣点"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="5">
                            <el-form-item label="助理:" prop="projectAssistantId">
                                <el-select v-model="objectDto.projectAssistantId" placeholder="助理::" size="mini" style="width: 150px;" v-on:change="selectAsName" >
                                    <el-option v-for="item in pmList" :key="item.id" :label="item.name" :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>

                        <el-col :span="5">
                            <el-form-item label="助理扣点:" prop="projectAssistantDeduct">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.projectAssistantDeduct" placeholder="请输入助理扣点"></el-input>
                            </el-form-item>
                        </el-col>

  
                        <el-col :span="5">
                            <el-form-item label="销售姓名:" prop="salesUserName">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.salesUserName" placeholder="请输入销售姓名"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="销售扣点:" prop="salesUserDeduct">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.salesUserDeduct" placeholder="请输入销售扣点"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="5">
                            <el-form-item label="渠道姓名:" prop="channelName">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.channelName" placeholder="请输入渠道姓名"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="渠道扣点:" prop="channelDeduct">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.channelDeduct" placeholder="请输入渠道扣点"></el-input>
                            </el-form-item>
                        </el-col>


                        <el-col :span="5">
                            <el-form-item label="项目名称:" prop="projectName">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.projectName" placeholder="请输入项目"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="5">
                            <el-form-item label="合同金额:" prop="totalIncome">
                                <el-input size="mini" type="number" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.totalIncome" placeholder="请输入销售额"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="doAddObj">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

export default {
    name: 'ProjectInfo',
    data() {
        return {
            netEditAuth: false,
            searchValue: {
                status:''
            },
            status:[
                {'id':'','name':'全部'},
                // {'id':'0','name':'未审批'},
                {'id':'1','name':'已通过'},
                {'id':'2','name':'已拒绝'},
                ],
            title: '',
            importDataBtnText: '导入数据',
            importDataBtnIcon: 'el-icon-upload2',
            importDataDisabled: false,
            showAdvanceSearchView: false,
            loading: false,
            popVisible: false,
            popVisible2: false,
            dialogVisible: false,
            total: 0,
            page: 1,
            keyword: '',
            size: 10,
            saveType:0,
            objectDtos:[],
            objectDto:{
                projectId:"",                projectInfoApproveId:"",                projectManagerId:"",                projectManagerName:"",                projectManagerDeduct:"",                projectAssistantId:"",                projectAssistantName:"",                projectAssistantDeduct:"",                salesUserId:"",                salesUserName:"",                salesUserDeduct:"",                channelId:"",                channelName:"",                channelDeduct:"",                projectClass:"",                businessDate:"",                customerId:"",                customerName:"",                companyName:"",                projectName:"",                phase:"",                projectDetail:"",                totalIncome:"",                actIncome:"",                status:"",                deductPercent:"",                approveTime:"",                approveUserId:"",                approveUserName:"",                approveReason:"",                enabled:"",                inputUser:"",                inputUserName:"",                inputTime:"",                updateUser:"",                updateUserName:"",                updateTime:"",                bonusStatus:"",                seventyExclude:"",                bonusStatusAs:""
            },
            rules: {
            },
            pmList:[]
        };
    },
    mounted() {
        this.getEditAuth();
        this.initData();
        this.initObjectDto();
        
    },
    methods:
    {

        onError(err, file, fileList) {   // eslint-disable-line no-unused-vars
            this.importDataBtnText = '导入数据';
            this.importDataBtnIcon = 'el-icon-upload2';
            this.importDataDisabled = false;
        },
        onSuccess(response, file, fileList) { // eslint-disable-line no-unused-vars
            this.importDataBtnText = '导入数据';
            this.importDataBtnIcon = 'el-icon-upload2';
            this.importDataDisabled = false;
            this.initObjectDto();
        },
        beforeUpload() {
            this.importDataBtnText = '正在导入';
            this.importDataBtnIcon = 'el-icon-loading';
            this.importDataDisabled = true;
        },
        exportData() {
            window.open(this.ph + '/projectInfo/basic/export', '_parent');
        },
        emptyObjectDto() {
            this.objectDto = {
                projectId:"",                projectManagerId:"",                projectManagerName:"",                projectAssistantId:"",                projectAssistantName:"",                projectClass:"",                businessDate:"",                companyName:"",                projectName:"",                phase:"",                projectDetail:"",                totalIncome:"",                actIncome:"",                status:"",                deductPercent:"",                approveTime:"",                approveUserId:"",                approveUserName:"",                approveReason:"",                enabled:"",                inputUser:"",                inputUserName:"",                inputTime:"",                updateUser:"",                updateUserName:"",                updateTime:""
            };
        },
        showEditObj(data) {
            this.title = '编辑信息';
            this.saveType=2;
            this.objectDto = data;
            this.dialogVisible = true;
        },
        deleteObj(data) {
            var params={
                projectId:data.projectId
            }
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.postRequest('/projectInfo/basic/delete' , params ).then((resp) => {
                        if (resp) {
                            this.initObjectDto();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        applyObj(data){ //提交审核
            var params={
                projectId:data.projectId
            }
            if (data.projectId) {
                 this.postRequest('/projectInfo/basic/apply', params).then((resp) => {
                            if (resp) {
                                this.initObjectDto();
                            }
                        });
            }
        },
        doAddObj() {
            if (this.objectDto.projectId) {
                var params={
                    saveType:this.saveType,
                    projectInfo:this.objectDto
                }
                this.$refs['objectDtoForm'].validate((valid) => {
                    if (valid) {
                        this.postRequest('/projectInfo/basic/addOrUpdate', params).then((resp) => {
                            if (resp) {
                                this.dialogVisible = false;
                                this.initObjectDto();
                            }
                        });
                    }
                });
            } else {
                this.$message({
                        type: 'info',
                        message: '数据异常,请退出重试',
                    });
            }
        },
        handleNodeClick(data) {
            this.inputDepName = data.name;
            this.objectDto.departmentId = data.id;
            this.popVisible = !this.popVisible;
        },
        showDepView() {
            this.popVisible = !this.popVisible;
        },
        showDepView2() {
            this.popVisible2 = !this.popVisible2;
        },
        sizeChange(currentSize) {
            this.size = currentSize;
            this.initObjectDto();
        },
        currentChange(currentPage) {
            this.page = currentPage;
            this.initObjectDto();
        },
        showAddObjView() {
            this.emptyObjectDto();
            this.title = '添加数据';
            this.saveType = 1;
            this.getMaxSerialNo();
            this.dialogVisible = true;
        },
        getMaxSerialNo() {
            this.postRequest('/projectInfo/basic/getSerialNo',{}).then((resp) => {
                if (resp) {
                    this.objectDto.projectId = resp;
                }
            });
        },
        getEditAuth() {
            this.postRequest('/system/config/wx/checkAuth',{component:'netEditAuth'}).then((resp) => {
                if (resp.msg == 'ok') {
                    this.netEditAuth = true;
                }
            });
        },
        initObjectDto() {
            this.loading = true;
            let url = '/projectInfo/basic/query';
            let queryParams={
                page:this.page,
                size:this.size,
                projectInfo:{}
            };

            queryParams.projectInfo=this.searchValue;

            this.postRequest(url,queryParams).then((resp) => {
                this.loading = false;
                if (resp) {
                    if (typeof resp == 'string') {
                        resp = JSON.parse(resp);
                    }
                    this.objectDtos = resp.data;
                    this.total = resp.total;
                }
            });
        },
        initData(){
            this.postRequest('/system/userInfo/getPMList',{}).then((resp) => {
                if (resp) {
                     if (typeof resp == 'string') {
                        resp = JSON.parse(resp);
                    }
                    this.pmList = resp;
                }
            });
        },
        selectPmName(){
            let i = 0;
            for (i = 0;i<this.pmList.length;i++) {
                if (this.pmList[i].id == this.objectDto.projectManagerId){
                    this.objectDto.projectManagerName=this.pmList[i].name;
                    break;
                }
            }
        },
        selectAsName(){
            let i = 0;
            for (i = 0;i<this.pmList.length;i++) {
                if (this.pmList[i].id == this.objectDto.projectAssistantId){
                    this.objectDto.projectAssistantName=this.pmList[i].name;
                    break;
                }
            }
        },
        statusFormat:function(row) {
            if (row.status == '0') {
                return '未审批';
            } else if (row.status == '1') {
                return '已通过';
            } else if (row.status == '2') {
                return '已拒绝';
            }
        },
        percentFormat:function(row, column, cellValue) {
            if(typeof(cellValue) != 'undefined'){
                return cellValue + "%";
            }else{
                return "";
            }
        }

    },
};
</script>

<style>
/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
    transition: all 0.8s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}
</style>