<template>
    <div>
        <div>
            <div style="display: flex;justify-content: space-between">
                
                <div>
                    <!--
                    <el-input placeholder="请输入对象户名进行搜索，可以直接回车搜索..." prefix-icon="el-icon-search" clearable @clear="initObjectDto" style="width: 350px;margin-right: 10px" v-model="keyword" @keydown.enter.native="initObjectDto" :disabled="showAdvanceSearchView"></el-input>
                    
                    <el-button icon="el-icon-search" type="primary" @click="initObjectDto" :disabled="showAdvanceSearchView">
                        搜索
                    </el-button>
                    -->
                    <el-button type="primary" @click="showAdvanceSearchView = !showAdvanceSearchView">
                        <i :class="showAdvanceSearchView?'fa fa-angle-double-up':'fa fa-angle-double-down'" aria-hidden="true"></i>
                        搜索条件
                    </el-button>
                </div>
                
                <div>
                    <el-button type="success" @click="exportData" icon="el-icon-download">
                        导出数据
                    </el-button>
                </div>
            </div>
            <transition name="slide-fade">
                <div v-show="showAdvanceSearchView" style="border: 1px solid #409eff;border-radius: 5px;box-sizing: border-box;padding: 5px;margin: 10px 0px;">
                    <el-row>
                        <!--
                        <el-col :span="5">
                            状态:
                            <el-select v-model="searchValue.status" placeholder="状态" size="mini" style="width: 130px;">
                                <el-option v-for="item in status" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-col>
                        -->
                        <el-col :span="5">
                            成本中心:
                            <el-input size="mini" style="width: 150px" prefix-icon="el-icon-edit" v-model="searchValue.costCenter" placeholder="请输入成本中心"></el-input>
                        </el-col>
                        <el-col :span="5">
                            客户名称:
                            <el-input size="mini" style="width: 150px" prefix-icon="el-icon-edit" v-model="searchValue.customerName" placeholder="请输入客户名称"></el-input>
                        </el-col>
                        <el-col :span="5">
                            项目名称:
                            <el-input size="mini" style="width: 150px" prefix-icon="el-icon-edit" v-model="searchValue.projectName" placeholder="请输入项目名称"></el-input>
                        </el-col>

                        <el-col :span="5" :offset="4">
                            <el-button size="mini" icon="el-icon-search" type="primary" @click="initObjectDto()">搜索</el-button>
                        </el-col>
                    </el-row>
                </div>
            </transition>
        </div>
        <div style="margin-top: 10px">
            <el-table :data="objectDtos" stripe border v-loading="loading" element-loading-text="正在加载..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" style="width: 100%">
                <el-table-column prop="approveTime" label="审批时间" align="center" width="120">
                </el-table-column>
                <el-table-column prop="customerName" label="客户名称" align="center" width="120">
                </el-table-column>
                <el-table-column prop="projectName" label="项目名称" align="center" width="120">
                </el-table-column>
                <el-table-column prop="invoiceAmount" label="开票金额" align="center" width="120">
                </el-table-column>
                <el-table-column prop="billingNumber" label="开票号码" align="center" width="120">
                </el-table-column>
                <el-table-column prop="projectAddress" label="项目地址" align="center" width="120">
                </el-table-column>
                <el-table-column prop="invoiceTitle" label="开票抬头" align="center" width="120">
                </el-table-column>
                
                <el-table-column prop="status" label="状态" align="center" width="80" :formatter="statusFormat" >
                </el-table-column>

                <el-table-column v-if="netEditAuth" fixed="right" width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button @click="showEditObj(scope.row)" style="padding: 3px" size="mini">编辑</el-button>
                        <el-button @click="deleteObj(scope.row)" style="padding: 3px" size="mini" type="danger">删除</el-button>
                    </template>
                </el-table-column>
                
            </el-table>
            <div style="display: flex;justify-content: flex-end">
                <el-pagination background @current-change="currentChange" @size-change="sizeChange" layout="sizes, prev, pager, next, jumper, ->, total, slot" :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="80%">
            <div>
                <el-form :model="objectDto" :rules="rules" ref="objectDtoForm">
                    <el-row>
                        <el-col :span="5">
                            <el-form-item label="流水号:" prop="serialNo">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.serialNo" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="客户编号:" prop="customerId">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.customerId" placeholder="请输入客户编号"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="客户名称:" prop="customerName">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.customerName" placeholder="请输入客户名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="项目编号:" prop="projectId">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.projectId" placeholder="请输入项目编号"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="项目名称:" prop="projectName">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.projectName" placeholder="请输入项目名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="项目地址:" prop="projectAddress">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.projectAddress" placeholder="请输入项目地址"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="开票金额:" prop="invoiceAmount">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.invoiceAmount" placeholder="请输入开票金额"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="备注:" prop="remark">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.remark" placeholder="请输入备注"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="开票抬头:" prop="invoiceTitle">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.invoiceTitle" placeholder="请输入开票抬头"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="公司名称:" prop="companyName">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.companyName" placeholder="请输入公司名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="状态:" prop="status">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.status" placeholder="请输入状态"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="税号:" prop="taxNumber">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.taxNumber" placeholder="请输入税号"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="地址:" prop="address">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.address" placeholder="请输入地址"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="电话:" prop="phone">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.phone" placeholder="请输入电话"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="开户行:" prop="openingBankName">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.openingBankName" placeholder="请输入开户行"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="开户行账号:" prop="openingBankNo">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.openingBankNo" placeholder="请输入开户行账号"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="开票号码:" prop="billingNumber">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.billingNumber" placeholder="请输入开票号码"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="申请人:" prop="applyUser">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.applyUser" placeholder="请输入申请人"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="申请日期:" prop="applyDate">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.applyDate" placeholder="请输入申请日期"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="审批意见:" prop="approveReason">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.approveReason" placeholder="请输入审批意见"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="审批时间:" prop="approveTime">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.approveTime" placeholder="请输入审批时间"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="审批人ID:" prop="approveUserId">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.approveUserId" placeholder="请输入审批人ID"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="审批人姓名:" prop="approveUserName">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.approveUserName" placeholder="请输入审批人姓名"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="是否有效:" prop="enabled">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.enabled" placeholder="请输入是否有效"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="登记人ID:" prop="inputUser">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.inputUser" placeholder="请输入登记人ID"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="登记人姓名:" prop="inputUserName">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.inputUserName" placeholder="请输入登记人姓名"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="插入时间:" prop="inputTime">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.inputTime" placeholder="请输入插入时间"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="更新人ID:" prop="updateUser">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.updateUser" placeholder="请输入更新人ID"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="更新人姓名:" prop="updateUserName">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.updateUserName" placeholder="请输入更新人姓名"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="更新时间:" prop="updateTime">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.updateTime" placeholder="请输入更新时间"></el-input>
                            </el-form-item>
                        </el-col>

                    </el-row>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="doAddObj">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

export default {
    name: 'FinanceInvoiceInfo',
    data() {
        return {
            netEditAuth: false,
            searchValue: {
                status:''
            },
            status:[
                {'id':'','name':'全部'},
                {'id':'0','name':'未审批'},
                {'id':'1','name':'已通过'},
                {'id':'2','name':'已拒绝'},
                ],
            title: '',
            importDataBtnText: '导入数据',
            importDataBtnIcon: 'el-icon-upload2',
            importDataDisabled: false,
            showAdvanceSearchView: false,
            loading: false,
            popVisible: false,
            popVisible2: false,
            dialogVisible: false,
            total: 0,
            page: 1,
            keyword: '',
            size: 10,
            saveType:0,
            objectDtos:[],
            objectDto:{
                serialNo:"",                customerId:"",                customerName:"",                projectId:"",                projectName:"",                projectAddress:"",                invoiceAmount:"",                remark:"",                invoiceTitle:"",                companyName:"",                status:"",                taxNumber:"",                address:"",                phone:"",                openingBankName:"",                openingBankNo:"",                billingNumber:"",                applyUser:"",                applyDate:"",                approveReason:"",                approveTime:"",                approveUserId:"",                approveUserName:"",                enabled:"",                inputUser:"",                inputUserName:"",                inputTime:"",                updateUser:"",                updateUserName:"",                updateTime:""
            },
            rules: {
            },
        };
    },
    mounted() {
        this.getEditAuth();
        this.initObjectDto();
    },
    methods:
    {

        onError(err, file, fileList) {   // eslint-disable-line no-unused-vars
            this.importDataBtnText = '导入数据';
            this.importDataBtnIcon = 'el-icon-upload2';
            this.importDataDisabled = false;
        },
        onSuccess(response, file, fileList) { // eslint-disable-line no-unused-vars
            this.importDataBtnText = '导入数据';
            this.importDataBtnIcon = 'el-icon-upload2';
            this.importDataDisabled = false;
            this.initObjectDto();
        },
        beforeUpload() {
            this.importDataBtnText = '正在导入';
            this.importDataBtnIcon = 'el-icon-loading';
            this.importDataDisabled = true;
        },
        exportData() {
            window.open(this.ph + '/invoiceInfo/basic/export', '_parent');
        },
        emptyObjectDto() {
            this.objectDto = {
                serialNo:"",                customerId:"",                customerName:"",                projectId:"",                projectName:"",                projectAddress:"",                invoiceAmount:"",                remark:"",                invoiceTitle:"",                companyName:"",                status:"",                taxNumber:"",                address:"",                phone:"",                openingBankName:"",                openingBankNo:"",                billingNumber:"",                applyUser:"",                applyDate:"",                approveReason:"",                approveTime:"",                approveUserId:"",                approveUserName:"",                enabled:"",                inputUser:"",                inputUserName:"",                inputTime:"",                updateUser:"",                updateUserName:"",                updateTime:""
            };
        },
        showEditObj(data) {
            this.title = '编辑信息';
            this.saveType=2;
            this.objectDto = data;
            this.dialogVisible = true;
        },
        deleteObj(data) {
            var params={
                serialNo:data.serialNo
            }
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.postRequest('/invoiceInfo/basic/delete' , params ).then((resp) => {
                        if (resp) {
                            this.initObjectDto();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        applyObj(data){ //提交审核
            var params={
                serialNo:data.serialNo
            }
            if (data.serialNo) {
                 this.postRequest('/invoiceInfo/basic/apply', params).then((resp) => {
                            if (resp) {
                                this.initObjectDto();
                            }
                        });
            }
        },
        doAddObj() {
            if (this.objectDto.serialNo) {
                var params={
                    saveType:this.saveType,
                    invoiceInfo:this.objectDto
                }
                this.$refs['objectDtoForm'].validate((valid) => {
                    if (valid) {
                        this.postRequest('/invoiceInfo/basic/addOrUpdate', params).then((resp) => {
                            if (resp) {
                                this.dialogVisible = false;
                                this.initObjectDto();
                            }
                        });
                    }
                });
            } else {
                this.$message({
                        type: 'info',
                        message: '数据异常,请退出重试',
                    });
            }
        },
        handleNodeClick(data) {
            this.inputDepName = data.name;
            this.objectDto.departmentId = data.id;
            this.popVisible = !this.popVisible;
        },
        showDepView() {
            this.popVisible = !this.popVisible;
        },
        showDepView2() {
            this.popVisible2 = !this.popVisible2;
        },
        sizeChange(currentSize) {
            this.size = currentSize;
            this.initObjectDto();
        },
        currentChange(currentPage) {
            this.page = currentPage;
            this.initObjectDto();
        },
        showAddObjView() {
            this.emptyObjectDto();
            this.title = '添加数据';
            this.saveType=1;
            this.getMaxSerialNo();
            this.dialogVisible = true;
        },
        getMaxSerialNo() {
            this.postRequest('/invoiceInfo/basic/getSerialNo',{}).then((resp) => {
                if (resp) {
                    this.objectDto.serialNo = resp;
                }
            });
        },
        getEditAuth() {
            this.postRequest('/system/config/wx/checkAuth',{component:'netEditAuth'}).then((resp) => {
                if (resp.msg == 'ok') {
                    this.netEditAuth = true;
                }
            });
        },
        initObjectDto() {
            this.loading = true;
            let url = '/invoiceInfo/basic/query';
            let queryParams={
                page:this.page,
                size:this.size,
                invoiceInfo:{}
            };

            queryParams.invoiceInfo=this.searchValue;
            
            this.postRequest(url,queryParams).then((resp) => {
                this.loading = false;
                if (resp) {
                    if (typeof resp == 'string') {
                        resp = JSON.parse(resp);
                    }
                    this.objectDtos = resp.data;
                    this.total = resp.total;
                }
            });
        },
        statusFormat:function(row) {
            if (row.status == '0') {
                return '未审批';
            } else if (row.status == '1') {
                return '已通过';
            } else if (row.status == '2') {
                return '已拒绝';
            }
        }
    },
};
</script>

<style>
/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
    transition: all 0.8s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}
</style>