<template>
    <div>
        <div>
            <div style="display: flex;justify-content: space-between">
                <div>
                    <el-input placeholder="请输入对象户名进行搜索，可以直接回车搜索..." prefix-icon="el-icon-search" clearable @clear="initObjectDto" style="width: 350px;margin-right: 10px" v-model="keyword" @keydown.enter.native="initObjectDto" ></el-input>
                    <el-button icon="el-icon-search" type="primary" @click="initObjectDto" :disabled="showAdvanceSearchView">
                        搜索
                    </el-button>
                    <el-button type="primary" @click="showAdvanceSearchView = !showAdvanceSearchView">
                        <i :class="showAdvanceSearchView?'fa fa-angle-double-up':'fa fa-angle-double-down'" aria-hidden="true"></i>
                        搜索条件
                    </el-button>
                </div>
                <div>
                    <el-upload :show-file-list="false" :before-upload="beforeUpload" :on-success="onSuccess" :on-error="onError" :disabled="importDataDisabled" style="display: inline-flex;margin-right: 8px" action="/wjcom/bankAccountDetail/basic/import">
                        <el-button :disabled="importDataDisabled" type="success" :icon="importDataBtnIcon">
                            {{importDataBtnText}}
                        </el-button>
                    </el-upload>
                    <el-button type="success" @click="exportData" icon="el-icon-download">
                        导出数据
                    </el-button>
                    <el-button type="primary" icon="el-icon-plus" @click="showAddEmpView">
                        添加数据
                    </el-button>
                </div>
            </div>
            <transition name="slide-fade">
                <div v-show="showAdvanceSearchView" style="border: 1px solid #409eff;border-radius: 5px;box-sizing: border-box;padding: 5px;margin: 10px 0px;">
                    <el-row>
                        <el-col :span="5">
                            状态:
                            <el-select v-model="searchValue.status" placeholder="状态" size="mini" style="width: 130px;">
                                <el-option v-for="item in status" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="5" :offset="4">
                            <el-button size="mini" icon="el-icon-search" type="primary" @click="initObjectDto()">搜索</el-button>
                        </el-col>
                    </el-row>
                </div>
            </transition>
        </div>
        <div style="margin-top: 10px">
            <el-table :data="objectDtos" stripe border v-loading="loading" element-loading-text="正在加载..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" style="width: 100%">
                <el-table-column  prop="status"   align="left" label="状态" width="100" :formatter = "statusFormat" >
                </el-table-column>
                <el-table-column prop="classType"  align="left" label="分类" width="140">
                </el-table-column>
                <el-table-column prop="type" label="类别" align="left" width="85">
                </el-table-column>
                <el-table-column prop="businessDate" label="日期" align="left" width="140">
                </el-table-column>
                <el-table-column prop="debit" width="140" align="left" label="借方">
                </el-table-column>
                <el-table-column prop="credit" width="140" align="left" label="贷方">
                </el-table-column>
                <el-table-column prop="balance" width="140" align="left" label="余额">
                </el-table-column>
                <el-table-column prop="accountName" width="250" label="对象户名">
                </el-table-column>
                <el-table-column prop="reason" width="250" label="事由">
                </el-table-column>
                <el-table-column prop="inputUserName" width="80" align="left" label="创建人">
                </el-table-column>
                <el-table-column prop="inputTime" width="120" align="left" label="导入时间">
                </el-table-column>
                <el-table-column prop="approveUserName" width="100" align="left" label="审批人">
                </el-table-column>
                <el-table-column fixed="right" width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.status != '1' " @click="showEditObj(scope.row)" style="padding: 3px" size="mini">编辑</el-button>
                        <el-button v-if="scope.row.status != '1' " @click="deleteObj(scope.row)" style="padding: 3px" size="mini" type="danger">删除</el-button>
                        <el-button v-if="scope.row.status == '2' " @click="applyObj(scope.row)" style="padding: 3px" size="mini">重提</el-button>   
                    </template>
                </el-table-column>
            </el-table>
            <div style="display: flex;justify-content: flex-end">
                <el-pagination background @current-change="currentChange" @size-change="sizeChange" layout="sizes, prev, pager, next, jumper, ->, total, slot" :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="80%">
            <div>
                <el-form :model="objectDto" :rules="rules" ref="objectDtoForm">
                    <el-row>
                        
                        <el-col :span="6">
                            <el-form-item label="分类:" prop="classType">
                                <el-input size="mini" style="width: 150px" prefix-icon="el-icon-edit" v-model="objectDto.classType" placeholder="请输入分类"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="5">
                            <el-form-item label="类别:" prop="type">
                                <el-input size="mini" style="width: 150px" prefix-icon="el-icon-edit" v-model="objectDto.type" placeholder="请输入类别"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="日期:" prop="businessDate">
                                <el-date-picker v-model="objectDto.businessDate" size="mini" type="date" value-format="yyyy-MM-dd" style="width: 150px;" placeholder="请输入日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="借方:" prop="debit">
                                <el-input size="mini" style="width: 150px" prefix-icon="el-icon-edit" v-model="objectDto.debit" placeholder="请输入借方"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="贷方:" prop="credit">
                                <el-input size="mini" style="width: 150px" prefix-icon="el-icon-edit" v-model="objectDto.credit" placeholder="请输入贷方"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="余额:" prop="balance">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDto.balance" placeholder="请输入余额"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="对象户名:" prop="accountName">
                                <el-input size="mini" style="width: 150px" prefix-icon="el-icon-message" v-model="objectDto.accountName" placeholder="请输入对象户名"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7">
                            <el-form-item label="事由:" prop="reason">
                                <el-input size="mini" style="width: 200px" prefix-icon="el-icon-edit" v-model="objectDto.reason" placeholder="请输入事由"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                   
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="doAddObj">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

export default {
    name: 'FinanceBankDetail',
    data() {
        return {
            searchValue: {
                status:''
            },
            status:[
                {'id':'','name':'全部'},
                {'id':'0','name':'未审批'},
                {'id':'1','name':'已通过'},
                {'id':'2','name':'已拒绝'},
                ],
            title: '',
            importDataBtnText: '导入数据',
            importDataBtnIcon: 'el-icon-upload2',
            importDataDisabled: false,
            showAdvanceSearchView: false,
            loading: false,
            popVisible: false,
            popVisible2: false,
            dialogVisible: false,
            total: 0,
            page: 1,
            keyword: '',
            size: 10,
            objectDtos:[],
            objectDto:{
                serialNo:"",
                userId:"",
                userName:"",
                type:"",
                classType:"",
                projectType:"",
                businessDate:"",
                debit:"",
                credit:"",    
                balance:"",   
                accountName:"",   
                reason:"",    
                status:"",    
                approveTime:"",   
                approveUserId:"", 
                approveReason:"", 
                enabled:"",   
                inputUser:"",
                inputTime:"",
                updateUser:"",
                updateTime:""
            },
            rules: {
            },
        };
    },
    mounted() {
        this.initObjectDto();
    },
    methods: 
    {
        searvhViewHandleNodeClick(data) {
            this.inputDepName = data.name;
            this.searchValue.departmentId = data.id;
            this.popVisible2 = !this.popVisible2;
        },
        onError(err, file, fileList) {   // eslint-disable-line no-unused-vars
            this.importDataBtnText = '导入数据';
            this.importDataBtnIcon = 'el-icon-upload2';
            this.importDataDisabled = false;
        },
        onSuccess(response, file, fileList) { // eslint-disable-line no-unused-vars
            this.importDataBtnText = '导入数据';
            this.importDataBtnIcon = 'el-icon-upload2';
            this.importDataDisabled = false;
            this.initObjectDto();
        },
        beforeUpload() {
            this.importDataBtnText = '正在导入';
            this.importDataBtnIcon = 'el-icon-loading';
            this.importDataDisabled = true;
        },
        exportData() {
            window.open(this.ph + '/bankAccountDetail/basic/export', '_parent');
        },
        emptyObjectDto() {
            this.objectDto = {
                serialNo:"",
                userId:"",
                userName:"",
                type:"",
                classType:"",
                projectType:"",
                businessDate:"",
                debit:"",
                credit:"",    
                balance:"",   
                accountName:"",   
                reason:"",    
                status:"",    
                approveTime:"",   
                approveUserId:"", 
                approveReason:"", 
                enabled:"",   
                inputUser:"",
                inputTime:"",
                updateUser:"",
                updateTime:""
            };
        },
        showEditObj(data) {
            this.title = '编辑信息';
            this.objectDto = data;
            this.dialogVisible = true;
        },
        deleteObj(data) {
            var params={
                serialNo:data.serialNo
            }
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.postRequest('/bankAccountDetail/basic/delete' , params ).then((resp) => {
                        if (resp) {
                            this.initObjectDto();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        applyObj(data) { //重提
            var params={
                serialNo:data.serialNo
            }
            this.$confirm('是否重新提交该笔数据?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.postRequest('/bankAccountDetail/basic/apply' , params ).then((resp) => {
                        if (resp) {
                            this.initObjectDto();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        doAddObj() {
            if (this.objectDto.serialNo) {
                this.$refs['objectDtoForm'].validate((valid) => {
                    if (valid) {
                        this.postRequest('/bankAccountDetail/basic/addOrUpdate', this.objectDto).then((resp) => {
                            if (resp) {
                                this.dialogVisible = false;
                                this.initObjectDto();
                            }
                        });
                    }
                });
            } else {
                this.$message({
                        type: 'info',
                        message: '数据异常,请退出重试',
                    });
            }
        },
        handleNodeClick(data) {
            this.inputDepName = data.name;
            this.objectDto.departmentId = data.id;
            this.popVisible = !this.popVisible;
        },
        showDepView() {
            this.popVisible = !this.popVisible;
        },
        showDepView2() {
            this.popVisible2 = !this.popVisible2;
        },
        sizeChange(currentSize) {
            this.size = currentSize;
            this.initObjectDto();
        },
        currentChange(currentPage) {
            this.page = currentPage;
            this.initObjectDto();
        },
        showAddEmpView() {
            this.emptyObjectDto();
            this.title = '添加银行流水';
            this.getMaxSerialNo();
            this.dialogVisible = true;
        },
        getMaxSerialNo() {
            this.postRequest('/bankAccountDetail/basic/getSerialNo',{}).then((resp) => {
                
                if (resp) {
                    this.objectDto.serialNo = resp;
                }
            });
        },
        initObjectDto() {
            this.loading = true;
            let url = '/bankAccountDetail/basic/?page=' + this.page + '&size=' + this.size;
            if (this.searchValue.status) {
                url += '&status=' + this.searchValue.status;
            }
            if(this.keyword){
                url += '&accountName=' + this.keyword;
            }
            this.getRequest(url).then((resp) => {
                this.loading = false;
                if (resp) {
                    if (typeof resp == 'string') {
                        resp = JSON.parse(resp);
                    }
                    this.objectDtos = resp.data;
                    this.total = resp.total;
                }
            });
        },
        statusFormat:function(row) {
            if (row.status == '0') {
                return '未审批';
            } else if (row.status == '1') {
                return '已通过';
            } else if (row.status == '2') {
                return '已拒绝';
            }
        }
    },
};
</script>

<style>
/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
    transition: all 0.8s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}
</style>