<template>
    <div>
        <div>
            <div style="display: flex;justify-content: space-between">
                <div>
                    <el-button type="primary" @click="showAdvanceSearchView = !showAdvanceSearchView">
                        <i :class="showAdvanceSearchView?'fa fa-angle-double-up':'fa fa-angle-double-down'" aria-hidden="true"></i>
                        搜索条件
                    </el-button>
                </div>
                <!-- <div>
                    <el-button type="primary" icon="el-icon-plus" @click="showAddObjView">
                        添加数据
                    </el-button>  
                </div> -->
            </div>
            <transition name="slide-fade">
                <div v-show="showAdvanceSearchView" style="border: 1px solid #409eff;border-radius: 5px;box-sizing: border-box;padding: 5px;margin: 10px 0px;">
                    <el-row>
                        <el-col :span="6">
                            店铺名称:
                            <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="searchValue.brandName" placeholder="请输入公司名称"></el-input>
                        </el-col>
                       
                        <el-col :span="5" :offset="4">
                            <el-button size="mini" icon="el-icon-search" type="primary" @click="initObjectDTO()">搜索</el-button>
                        </el-col>
                    </el-row>
                </div>
            </transition>
        </div>
        <div style="margin-top: 10px">
            <el-table :data="objectDTOs" stripe border v-loading="loading" element-loading-text="正在加载..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" style="width: 100%">
                <el-table-column  prop="shopName" label="门店名称"  align="left" width="240">
                </el-table-column>
                <el-table-column prop="userName" label="人员名称" align="left" width="120">
                </el-table-column>
                <el-table-column prop="phone" label="手机号码" align="left" width="120">
                </el-table-column>
                <el-table-column prop="expiryDate" label="有效期" align="left" width="100">
                </el-table-column>
                <el-table-column prop="brandName" label="品牌名称" align="left" width="240">
                </el-table-column>
                <el-table-column prop="enabled" label="是否有效" align="left" width="80">
                </el-table-column>
                <el-table-column prop="inputUserId" label="登记人ID" align="left" width="80">
                </el-table-column>
                <el-table-column prop="inputUserName" label="登记人姓名" align="left" width="100">
                </el-table-column>
                <el-table-column prop="inputTime" label="插入时间" align="left" width="100">
                </el-table-column>
                <el-table-column prop="updateUserId" label="更新人ID" align="left" width="80">
                </el-table-column>
                <el-table-column prop="updateUserName" label="更新人姓名" align="left" width="100">
                </el-table-column>
                <el-table-column prop="updateTime" label="更新时间" align="left" width="100">
                </el-table-column>

                <el-table-column 
                fixed="right" width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.isFirstUpdate == '1'" @click="setExpiryDateObj(scope.row)" style="padding: 3px" size="mini">设置有效期</el-button>
                    </template>
                </el-table-column>
                
            </el-table>
            <div style="display: flex;justify-content: flex-end">
                <el-pagination background @current-change="currentChange" @size-change="sizeChange" layout="sizes, prev, pager, next, jumper, ->, total, slot" :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
            <div>
                <el-form :model="objectDTO" :rules="rules" ref="objectDTOForm">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="设置有效期:" prop="expiryDate">
                                <el-date-picker
                                    v-model="expiryDate"
                                    type="date"
                                    placeholder="请设置设置有效期"
                                    format="yyyy-MM-dd"
                                    style="width: 250px" 
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions"
                                    >
                                </el-date-picker>
                                <!-- <el-input size="mini" style="width: 250px" prefix-icon="el-icon-edit" v-model="objectDTO.expiryDate" placeholder="请输入品牌名称"></el-input> -->
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="doAddObj">确 定</el-button>
            </span>
        </el-dialog>

        <!-- <el-dialog :title="title" :visible.sync="dialogAddVisible" width="30%">
            <div>
                <el-form :model="objectInfo" :rules="rules" ref="objectForm">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="品牌名称:" prop="brandName">
                                <el-input disabled="true" size="mini" style="width: 250px" prefix-icon="el-icon-edit" v-model="objectDTO.serialNo" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="姓名:" prop="userName">
                                <el-input size="mini" style="width: 250px" prefix-icon="el-icon-edit" v-model="objectDTO.companyName" placeholder="请输入公司名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="手机号:" prop="phone">
                                <el-input size="mini" style="width: 250px" prefix-icon="el-icon-edit" v-model="objectDTO.creditCode" placeholder="请输入公司代码"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogAddVisible = false">取 消</el-button>
                <el-button type="primary" @click="doAddObj">确 定</el-button>
            </span>
        </el-dialog> -->
    </div>
</template>

<script>

export default {
    name: 'BrandInfoDetail',
    data() {
        return {
            netEditAuth: false,
            searchValue: {

            },
            title: '',
            importDataBtnText: '导入数据',
            importDataBtnIcon: 'el-icon-upload2',
            importDataDisabled: false,
            showAdvanceSearchView: false,
            loading: false,
            popVisible: false,
            popVisible2: false,
            dialogVisible: false,
            dialogAddVisible:false,
            total: 0,
            page: 1,
            keyword: '',
            size: 10,
            saveType:0,
            objectDTOs:[],
            objectDTO:{
               
            },
            rules: {
            },
            expiryDate:"",
            serialNo:'',
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 3600 * 1000 * 24;
                }
            },
        };
    },
    mounted() {
        // this.getEditAuth();
        this.brandId = this.$route.query.brandId
        this.brandName = this.$route.query.brandName
        this.initObjectDTO();
    },
    methods:{

        onError(err, file, fileList) {   // eslint-disable-line no-unused-vars
            this.importDataBtnText = '导入数据';
            this.importDataBtnIcon = 'el-icon-upload2';
            this.importDataDisabled = false;
        },
        onSuccess(response, file, fileList) { // eslint-disable-line no-unused-vars
            this.importDataBtnText = '导入数据';
            this.importDataBtnIcon = 'el-icon-upload2';
            this.importDataDisabled = false;
            this.initObjectDTO();
        },
        beforeUpload() {
            this.importDataBtnText = '正在导入';
            this.importDataBtnIcon = 'el-icon-loading';
            this.importDataDisabled = true;
        },
        exportData() {
            window.open(this.ph + '/brandInfo/basic/export', '_parent');
        },
        emptyObjectDTO() {
            this.objectInfo = {
                
            };  
        },
        disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7; // 8.64e7 毫秒数代表一天
        },
        showAddObjView() {
            this.emptyObjectDTO();
            this.title = '新增品牌管理员';
            this.dialogAddVisible = true;
        },
        setExpiryDateObj(data) {
            this.title = '设置有效期';
            this.saveType=2;
            this.dialogVisible = true;
            this.expiryDate = data.expiryDate
            this.serialNo = data.serialNo
        },
        doAddObj() {
            if (this.serialNo) {
                var params={
                    serialNo:this.serialNo,
                    expiryDate: this.expiryDate,
                }
                this.$refs['objectDTOForm'].validate((valid) => {
                    if (valid) {
                        this.postRequest('/shopUser/basic/updateExpiryDate', params).then((resp) => {
                            if (resp) {
                                this.dialogVisible = false;
                                this.initObjectDTO();
                            }
                        });
                    }
                });
            } else {
                this.$message({
                        type: 'info',
                        message: '数据异常,请退出重试',
                    });
            }
        },
        sizeChange(currentSize) {
            this.size = currentSize;
            this.initObjectDTO();
        },
        currentChange(currentPage) {
            this.page = currentPage;
            this.initObjectDTO();
        },
        getEditAuth() {
            // this.postRequest('/system/config/wx/checkAuth',{component:'netEditAuth'}).then((resp) => {
            //     if (resp.status == 200) {
            //         this.netEditAuth = true;
            //     }
            // });
        },
        initObjectDTO() {
            this.loading = true;
            let url = '/shopUser/basic/query';
            let queryParams={
                page:this.page,
                size:this.size,
                queryType: "99",
                shopName:this.searchValue,
                shopUser:{
                    brandId:this.brandId
                }
            };
            this.postRequest(url, queryParams).then((resp) => {
                this.loading = false;
                console.log(`resp`, resp)
                if (resp.status == 200) {
                    const resultInfo = resp.obj || {}
                    this.objectDTOs = resultInfo.data;
                    this.total = resultInfo.total;
                }
            });
        },
    },
};
</script>

<style>
/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
    transition: all 0.8s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}
</style>