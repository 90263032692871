import { getRequest } from "./api";
export const initMenu = (router, store) => {
    if (store.state.routes.length > 0) {
        return;
    }
    getRequest("/system/config/menu").then(data => {
        if (data) {
            console.log(`data`, data)
            console.log(`router`, router)
            for(let i=0,len=data.length;i<len;i++){
                let record = data[i] || {}
                if(record['id'] == 36){
                    if((record['children'] || []).length>0){
                        record['children'] = [...record['children'], {
                            ...record,
                            component:'BrandShopDetail',
                            children:[],
                            name:"店铺",
                            id:"2008",
                            parentId: 36,
                            path: "/BrandShopDetail",
                            enabled: true,
                            hidden:true,
                            url:"",
                        }]
                    }
                }
            }
            let fmtRoutes = formatRoutes(data);
            router.addRoutes(fmtRoutes);
            store.commit('initRoutes', fmtRoutes);
            store.dispatch('connect');
        }
    })
}
export const formatRoutes = (routes) => {
    let fmRoutes = [];
    routes.forEach(router => {
        let {
            path,
            component,
            name,
            meta,
            iconCls,
            children,
            hidden
        } = router;
        if (children && children instanceof Array) {
            children = formatRoutes(children);
        }
        let fmRouter = {
            path: path,
            name: name,
            iconCls: iconCls,
            meta: meta,
            children: children,
            hidden,
            component(resolve) {
                if (component.startsWith("Home")) {
                    require(['../views/' + component + '.vue'], resolve);
                } else if (component.startsWith("Emp")) {
                    require(['../views/emp/' + component + '.vue'], resolve);
                } else if (component.startsWith("Per")) {
                    require(['../views/per/' + component + '.vue'], resolve);
                } else if (component.startsWith("Sal")) {
                    require(['../views/sal/' + component + '.vue'], resolve);
                } else if (component.startsWith("Sta")) {
                    require(['../views/sta/' + component + '.vue'], resolve);
                } else if (component.startsWith("Sys")) {
                    require(['../views/sys/' + component + '.vue'], resolve);
                } else if (component.startsWith("Finance")) {
                    require(['../views/finance/' + component + '.vue'], resolve);
                } else if (component.startsWith("Project")) {
                    require(['../views/project/' + component + '.vue'], resolve);
                }else if (component.startsWith("other")) {
                    require(['../views/other/' + component + '.vue'], resolve);
                }
            }
        }
        fmRoutes.push(fmRouter);
    })
    return fmRoutes;
}