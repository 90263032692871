import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from "../views/Login.vue";
import FriendChat from '../views/chat/FriendChat.vue'
import HrInfo from '../views/HrInfo.vue'
import FinanceBankDetail from '../views/finance/FinanceBankDetail.vue'
import FinancePayInfo from '../views/finance/FinancePayInfo.vue'
import FinanceAssistantPayInfo from "../views/finance/FinanceAssistantPayInfo"
import FinanceWorkerPayInfo from '../views/finance/FinanceWorkerPayInfo.vue';
import FinanceInvoiceInfo from '../views/finance/FinanceInvoiceInfo.vue'
import FinanceBillInfo from '../views/finance/FinanceBillInfo.vue'
import FinanceReceiptInfo from '../views/finance/FinanceReceiptInfo.vue'
import FinanceCollectInfo from '../views/finance/FinanceCollectInfo.vue'
import FinanceYiJiangBao from '../views/finance/FinanceYiJiangBao.vue'

import ProjectInfo from '../views/project/ProjectInfo.vue'
import ProjectInfoPM from '../views/project/ProjectInfoPM.vue'
import ProjectAmountLog from '../views/project/ProjectAmountLog.vue'
import ProjectNameLog from '../views/project/ProjectNameLog.vue'

import EmployeeInfo from '../views/employee/EmployeeInfo.vue'
import WorkerInfo from '../views/employee/WorkerInfo.vue'

import InvoiceObjectInfo from '../views/other/InvoiceObjectInfo.vue'
import BrandInfo from '../views/other/BrandInfo.vue'
import BrandShopDetail from '../views/other/BrandInfoDetail.vue'
import PayObjectInfo from '../views/other/PayObjectInfo.vue'
import QueryHistoryInfo from '../views/other/QueryHistoryInfo.vue'


Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        hidden: true
    },
    {
        path: "/home",
        name: "Home",
        component: Home,
        hidden: true,
        meta: {
            roles: ['admin', 'user']
        },
        children: [
            {
                path: '/chat',
                name: '在线聊天',
                component: FriendChat,
                hidden: true
            }, {
                path: '/hrinfo',
                name: '个人中心',
                component: HrInfo,
                hidden: true
            },
            {
                path: '/FinanceBankDetail',
                name: '银行流水',
                component: FinanceBankDetail,
                hidden: true
            },
            {
                path: '/FinancePayInfo',
                name: '支付信息',
                component: FinancePayInfo,
                hidden: true
            },
            {
                path: '/FinanceAssistantPayInfo',
                name: '助理人员支付信息',
                component: FinanceAssistantPayInfo,
                hidden: true
            },
            {
                path: '/FinanceWorkerPayInfo',
                name: '工人工资',
                component: FinanceWorkerPayInfo,
                hidden: true
            },
            {
                path: '/FinanceReceiptInfo',
                name: '收款信息',
                component: FinanceReceiptInfo,
                hidden: true
            },
            {
                path: '/FinanceInvoiceInfo',
                name: '开票信息',
                component: FinanceInvoiceInfo,
                hidden: true
            },
            {
                path: '/FinanceBillInfo',
                name: '收票信息',
                component: FinanceBillInfo,
                hidden: true
            },
            {
                path: '/FinanceYiJiangBao',
                name: '一匠宝',
                component: FinanceYiJiangBao,
                hidden: true
            },
            {
                path: '/ProjectInfo',
                name: '管理员合同',
                component: ProjectInfo,
                hidden: true
            },
            {
                path: '/ProjectAmountLog',
                name: '合同金额调整记录',
                component: ProjectAmountLog,
                hidden: true
            },
            {
                path: '/ProjectNameLog',
                name: '项目名称调整记录',
                component: ProjectNameLog,
                hidden: true
            },
            {
                path: '/ProjectInfoPM',
                name: '项目经理合同',
                component: ProjectInfoPM,
                hidden: true
            },
            {
                path: '/FinanceCollectInfo',
                name: '收票信息',
                component: FinanceCollectInfo,
                hidden: true
            },
            {
                path: '/EmployeeInfo',
                name: '员工数据库',
                component: EmployeeInfo,
                hidden: true
            },
            {
                path: '/WorkerInfo',
                name: '员工数据库',
                component: WorkerInfo,
                hidden: true
            },
            {
                path: '/InvoiceObjectInfo',
                name: '开票对象数据库',
                component: InvoiceObjectInfo,
                hidden: true
            },
            {
                path: '/BrandInfo',
                name: '品牌门店管理',
                component: BrandInfo,
                hidden: true,
            },
            {
                path: '/BrandShopDetail',
                name: '店铺',
                component: BrandShopDetail,
                hidden: true
            },
            {
                path: '/PayObjectInfo',
                name: '付款对象数据库',
                component: PayObjectInfo,
                hidden: true
            },
            {
                path: '/QueryHistoryInfo',
                name: '推荐搜索数据库',
                component: QueryHistoryInfo,
                hidden: true
            },
            
        ]
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '*',
        redirect: '/login'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
