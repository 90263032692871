<template>
    <div>
        <div>
            <div style="display: flex;justify-content: space-between">
                
                <div>
                    <!--
                    <el-input placeholder="请输入对象户名进行搜索，可以直接回车搜索..." prefix-icon="el-icon-search" clearable @clear="initObjectDTO" style="width: 350px;margin-right: 10px" v-model="keyword" @keydown.enter.native="initObjectDTO" :disabled="showAdvanceSearchView"></el-input>
                    
                    <el-button icon="el-icon-search" type="primary" @click="initObjectDTO" :disabled="showAdvanceSearchView">
                        搜索
                    </el-button>
                    -->
                    <el-button type="primary" @click="showAdvanceSearchView = !showAdvanceSearchView">
                        <i :class="showAdvanceSearchView?'fa fa-angle-double-up':'fa fa-angle-double-down'" aria-hidden="true"></i>
                        搜索条件
                    </el-button>
                </div>
                
                <div>
                    <!--
                    <el-upload :show-file-list="false" :before-upload="beforeUpload" :on-success="onSuccess" :on-error="onError" :disabled="importDataDisabled" style="display: inline-flex;margin-right: 8px" action="/wjcom/invoiceObjectInfo/basic/import">
                        <el-button :disabled="importDataDisabled" type="success" :icon="importDataBtnIcon">
                            {{importDataBtnText}}
                        </el-button>
                    </el-upload>
                    <el-button type="success" @click="exportData" icon="el-icon-download">
                        导出数据
                    </el-button>
                    -->
                    <el-button type="primary" icon="el-icon-plus" @click="showAddObjView">
                        添加数据
                    </el-button>
                    
                </div>
            </div>
            <transition name="slide-fade">
                <div v-show="showAdvanceSearchView" style="border: 1px solid #409eff;border-radius: 5px;box-sizing: border-box;padding: 5px;margin: 10px 0px;">
                    <el-row>
                        <el-col :span="6">
                            公司名称:
                            <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="searchValue.companyName" placeholder="请输入公司名称"></el-input>
                        </el-col>
                        <el-col :span="6">
                            公司代码:
                            <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="searchValue.creditCode" placeholder="请输入公司代码"></el-input>
                        </el-col>

                        <el-col :span="5" :offset="4">
                            <el-button size="mini" icon="el-icon-search" type="primary" @click="initObjectDTO()">搜索</el-button>
                        </el-col>
                    </el-row>
                </div>
            </transition>
        </div>
        <div style="margin-top: 10px">
            <el-table :data="objectDTOs" stripe border v-loading="loading" element-loading-text="正在加载..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" style="width: 100%">
                <el-table-column prop="companyName" label="公司名称" align="left" width="240">
                </el-table-column>
                <el-table-column prop="creditCode" label="公司代码" align="left" width="200">
                </el-table-column>
                <el-table-column prop="enabled" label="是否有效" align="left" width="80">
                </el-table-column>
                <el-table-column prop="inputUser" label="登记人ID" align="left" width="80">
                </el-table-column>
                <el-table-column prop="inputUserName" label="登记人姓名" align="left" width="100">
                </el-table-column>
                <el-table-column prop="inputTime" label="插入时间" align="left" width="100">
                </el-table-column>
                <el-table-column prop="updateUser" label="更新人ID" align="left" width="80">
                </el-table-column>
                <el-table-column prop="updateUserName" label="更新人姓名" align="left" width="100">
                </el-table-column>
                <el-table-column prop="updateTime" label="更新时间" align="left" width="100">
                </el-table-column>

                <el-table-column v-if="netEditAuth" fixed="right" width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.status != '1' " @click="showEditObj(scope.row)" style="padding: 3px" size="mini">编辑</el-button>
                        <el-button v-if="scope.row.status != '1' " @click="deleteObj(scope.row)" style="padding: 3px" size="mini" type="danger">删除</el-button>
                        <el-button v-if="scope.row.status == '2' " @click="applyObj(scope.row)" style="padding: 3px" size="mini">重提</el-button>   
                    </template>
                </el-table-column>
                
            </el-table>
            <div style="display: flex;justify-content: flex-end">
                <el-pagination background @current-change="currentChange" @size-change="sizeChange" layout="sizes, prev, pager, next, jumper, ->, total, slot" :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="80%">
            <div>
                <el-form :model="objectDTO" :rules="rules" ref="objectDTOForm">
                    <el-row>
                        <el-col :span="5">
                            <el-form-item label="编号:" prop="serialNo">
                                <el-input disabled="true" size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDTO.serialNo" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="公司名称:" prop="companyName">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDTO.companyName" placeholder="请输入公司名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="公司代码:" prop="creditCode">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDTO.creditCode" placeholder="请输入公司代码"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="是否有效:" prop="enabled">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDTO.enabled" placeholder="请输入是否有效"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="登记人ID:" prop="inputUser">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDTO.inputUser" placeholder="请输入登记人ID"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="登记人姓名:" prop="inputUserName">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDTO.inputUserName" placeholder="请输入登记人姓名"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="插入时间:" prop="inputTime">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDTO.inputTime" placeholder="请输入插入时间"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="更新人ID:" prop="updateUser">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDTO.updateUser" placeholder="请输入更新人ID"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="更新人姓名:" prop="updateUserName">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDTO.updateUserName" placeholder="请输入更新人姓名"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="更新时间:" prop="updateTime">
                                <el-input size="mini" style="width: 120px" prefix-icon="el-icon-edit" v-model="objectDTO.updateTime" placeholder="请输入更新时间"></el-input>
                            </el-form-item>
                        </el-col>

                    </el-row>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="doAddObj">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

export default {
    name: 'InvoiceObjectInfo',
    data() {
        return {
            netEditAuth: false,
            searchValue: {
                status:''
            },
            status:[
                {'id':'','name':'全部'},
                {'id':'0','name':'未审批'},
                {'id':'1','name':'已通过'},
                {'id':'2','name':'已拒绝'},
                ],
            title: '',
            importDataBtnText: '导入数据',
            importDataBtnIcon: 'el-icon-upload2',
            importDataDisabled: false,
            showAdvanceSearchView: false,
            loading: false,
            popVisible: false,
            popVisible2: false,
            dialogVisible: false,
            total: 0,
            page: 1,
            keyword: '',
            size: 10,
            saveType:0,
            objectDTOs:[],
            objectDTO:{
 serialNo:"", companyName:"", creditCode:"", enabled:"", inputUser:"", inputUserName:"", inputTime:"", updateUser:"", updateUserName:"", updateTime:""
            },
            rules: {
            },
        };
    },
    mounted() {
        this.getEditAuth();
        this.initObjectDTO();
    },
    methods:
    {

        onError(err, file, fileList) {   // eslint-disable-line no-unused-vars
            this.importDataBtnText = '导入数据';
            this.importDataBtnIcon = 'el-icon-upload2';
            this.importDataDisabled = false;
        },
        onSuccess(response, file, fileList) { // eslint-disable-line no-unused-vars
            this.importDataBtnText = '导入数据';
            this.importDataBtnIcon = 'el-icon-upload2';
            this.importDataDisabled = false;
            this.initObjectDTO();
        },
        beforeUpload() {
            this.importDataBtnText = '正在导入';
            this.importDataBtnIcon = 'el-icon-loading';
            this.importDataDisabled = true;
        },
        exportData() {
            window.open(this.ph + '/invoiceObjectInfo/basic/export', '_parent');
        },
        emptyObjectDTO() {
            this.objectDTO = {
 serialNo:"", companyName:"", creditCode:"", enabled:"", inputUser:"", inputUserName:"", inputTime:"", updateUser:"", updateUserName:"", updateTime:""
            };
        },
        showEditObj(data) {
            this.title = '编辑信息';
            this.saveType=2;
            this.objectDTO = data;
            this.dialogVisible = true;
        },
        deleteObj(data) {
            var params={
                serialNo:data.serialNo
            }
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.postRequest('/invoiceObjectInfo/basic/delete' , params ).then((resp) => {
                        if (resp) {
                            this.initObjectDTO();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        applyObj(data){ //提交审核
            var params={
                serialNo:data.serialNo
            }
            if (data.serialNo) {
                 this.postRequest('/invoiceObjectInfo/basic/apply', params).then((resp) => {
                            if (resp) {
                                this.initObjectDTO();
                            }
                        });
            }
        },
        doAddObj() {
            if (this.objectDTO.serialNo) {
                var params={
                    saveType:this.saveType,
                    invoiceObjectInfo:this.objectDTO
                }
                this.$refs['objectDTOForm'].validate((valid) => {
                    if (valid) {
                        this.postRequest('/invoiceObjectInfo/basic/addOrUpdate', params).then((resp) => {
                            if (resp) {
                                this.dialogVisible = false;
                                this.initObjectDTO();
                            }
                        });
                    }
                });
            } else {
                this.$message({
                        type: 'info',
                        message: '数据异常,请退出重试',
                    });
            }
        },
        handleNodeClick(data) {
            this.inputDepName = data.name;
            this.objectDTO.departmentId = data.id;
            this.popVisible = !this.popVisible;
        },
        showDepView() {
            this.popVisible = !this.popVisible;
        },
        showDepView2() {
            this.popVisible2 = !this.popVisible2;
        },
        sizeChange(currentSize) {
            this.size = currentSize;
            this.initObjectDTO();
        },
        currentChange(currentPage) {
            this.page = currentPage;
            this.initObjectDTO();
        },
        showAddObjView() {
            this.emptyObjectDTO();
            this.title = '添加数据';
            this.saveType=1;
            this.getMaxSerialNo();
            this.dialogVisible = true;
        },
        getMaxSerialNo() {
            this.postRequest('/invoiceObjectInfo/basic/getSerialNo',{}).then((resp) => {
                if (resp) {
                    this.objectDTO.serialNo = resp;
                }
            });
        },
        getEditAuth() {
            this.postRequest('/system/config/wx/checkAuth',{component:'netEditAuth'}).then((resp) => {
                if (resp.status == 200) {
                    this.netEditAuth = true;
                }
            });
        },
        initObjectDTO() {
            this.loading = true;
            let url = '/invoiceObjectInfo/basic/query';
            let queryParams={
                page:this.page,
                size:this.size,
                invoiceObjectInfo:this.searchValue
            };
            this.postRequest(url,queryParams).then((resp) => {
                this.loading = false;
                if (resp) {
                    if (typeof resp == 'string') {
                        resp = JSON.parse(resp);
                    }
                    this.objectDTOs = resp.data;
                    this.total = resp.total;
                }
            });
        },
        statusFormat:function(row) {
            if (row.status == '0') {
                return '未审批';
            } else if (row.status == '1') {
                return '已通过';
            } else if (row.status == '2') {
                return '已拒绝';
            }
        }
    },
};
</script>

<style>
/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
    transition: all 0.8s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}
</style>